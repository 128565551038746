import { v4 } from 'uuid';
import Vue from 'vue';

import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { phoneFormat, requiredRule, rfcHomoclaveFormat } from '@/vue-app/utils/form-rules';

// infrastructure
import PersonPresenter
  from '@/modules/on-boarding/person/infrastructure/presenters/person-presenter';

// Application
import GetOnBoardingPersonQuery
  from '@/modules/on-boarding/person/application/queries/get-on-boarding-person-query';
import SearchOnBoardingStepsQuery
  from '@/modules/on-boarding/steps/application/queries/search-on-boarding-steps-query';
import UpdateOnBoardingStepCommand
  from '@/modules/on-boarding/steps/application/commands/update-on-boarding-step-command';
import SearchAgreementsAsyncQuery
  from '@/modules/agreements/application/queries/search-agreements-async-query';
import SaveOnBoardingStepsDataService
  from '@/modules/on-boarding/steps/application/services/save-on-boarding-steps-data-service';
import CompleteOnBoardingCommand
  from '@/modules/on-boarding/status/application/commands/complete-on-boarding-command';
import GetInternetStatusService
  from '@/modules/internet-status/application/services/get-internet-status-service';

// Domain
import { StepEntity } from '@/modules/on-boarding/steps/domain/entities/step-entity';
import {
  CustomerAgreementEntity,
} from '@/modules/on-boarding/customer-agreements/domain/entities/customer-agreement-entity';
import {
  CustomerCellphoneDto,
} from '@/modules/on-boarding/customer-cellphone/domain/dtos/customer-cellphone-dto';
import {
  PreferredMeansOfContactDto,
} from '@/modules/on-boarding/preferred-means-of-contact/domain/dtos/preferred-means-of-contact-dto';
import { PersonEntity } from '@/modules/on-boarding/person/domain/entities/person-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';

export default class ConfirmPersonalInformationViewModel {
   @Inject(TYPES.GET_ON_BOARDING_PERSON_QUERY)
  readonly getOnBoardingPersonQuery!: GetOnBoardingPersonQuery;

  @Inject(TYPES.SEARCH_ON_BOARDING_STEPS_QUERY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.UPDATE_ON_BOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.SEARCH_AGREEMENTS_ASYNC_QUERY)
  readonly searchAgreementsAsyncQuery!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.SAVE_ON_BOARDING_STEPS_DATA_SERVICE)
  readonly saveOnBoardingStepsDataService!: SaveOnBoardingStepsDataService;

  @Inject(TYPES.COMPLETE_ON_BOARDING_COMMAND)
  readonly completeOnBoardingCommand!: CompleteOnBoardingCommand;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetimeValue!: DatetimeValue;

  @Inject(TYPES.GET_INTERNET_STATUS_SERVICE)
  readonly getInternetStatusService!: GetInternetStatusService;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  private readonly view: Vue;

  readonly i18n_namespace = 'sign-up.verify-identity.confirm';

  person_presenter?: PersonPresenter;

  current_step: null | StepEntity = null;

  country_codes = ['+52', '+1'];

  customer_agreements: Array<CustomerAgreementEntity> = [
    {
      description: '',
      agreement_type_id: '',
      id: v4(),
      accepted_on: null,
      was_accepted: false,
    },
  ];

  customer_cellphone_id = v4()

  customer_cellphone: CustomerCellphoneDto = {
    id: this.customer_cellphone_id,
    customer_cellphone_id: this.customer_cellphone_id,
    default: true,
    confirmed: true,
    name: 'personal',
    number: '',
    country_code: this.country_codes[0],
  }

  inputs: Partial<PersonEntity> = {
    curp: '',
    elector_key: '',
    id: '',
    date_of_birth: '',
    gender: {
      name: '',
    },
    nationality: {
      id: -1,
      name: '',
    },
    name: '',
    rfc: '',
  };

  rfc_clave = '';

  rfc_validated = true;

  rules = {
    rfc_clave: [requiredRule, rfcHomoclaveFormat],
    cellphone_country_code: [requiredRule],
    cellphone: [
      requiredRule,
      (value: string) => phoneFormat('+52', value),
    ],
  }

  confirm_data = false;

  valid_form = false;

  loading = false;

  preferred_means_of_contact_dto: PreferredMeansOfContactDto = {
    preferred_means_of_contact: '',
  }

  constructor(view: Vue) {
    this.view = view;
  }

  get internetStatus() {
    return this.getInternetStatusService.execute();
  }

  get canContinue() {
    return this.valid_form && this.confirm_data && this.internetStatus
      && this.preferred_means_of_contact_dto.preferred_means_of_contact;
  }

  get can_confirm_information() {
    return !this.valid_form || this.loading;
  }

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }
    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  }

  handleConfirmData = (confirm_data: boolean) => {
    this.customer_agreements[0].was_accepted = confirm_data;
    this.customer_agreements[0].accepted_on = this.datetimeValue.create();
  }

  handleSubmit = async (event: Event) => {
    event.preventDefault();

    this.loading = true;

    try {
      await this.updateOnBoardingStepCommand.execute({
        ...this.current_step!,
        payload: {
          ...this.current_step!.payload,
          person: {
            id: this.inputs.id,
            rfc: `${this.inputs.rfc}${this.rfc_clave}`,
          },
          customer_agreements: this.customer_agreements,
          customer_cellphone: this.customer_cellphone,
          preferred_means_of_contact: this.preferred_means_of_contact_dto,
        },
      });
      await this.saveOnBoardingStepsDataService.execute();
      await this.completeOnBoardingCommand.execute();
      this.view.$emit('nextStep');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_confirm'));
    } finally {
      this.loading = false;
    }
  }

  changeTitle = async () => {
    await this.view.$store.dispatch('layout/setLayoutHeader', {
      title: this.translate('verify_the_information'),
      right_action: {
        step: 4,
      },
    });
  }

  loadPersonalInformation = async () => {
    try {
      const person_data = await this.getOnBoardingPersonQuery.execute();
      this.rfc_validated = person_data.rfc_validated;
      this.person_presenter = new PersonPresenter(person_data);
      this.inputs = {
        ...person_data,
        name: `${person_data.name} ${person_data.last_name} ${person_data.second_last_name}`,
        rfc: person_data.rfc.substr(0, person_data.rfc.length - 3),
        gender: {
          name: person_data.gender!.name = this.person_presenter.translated_gender,
        },
        date_of_birth: person_data.date_of_birth = this.person_presenter.humanized_date_of_birth,
      };
      this.rfc_clave = person_data.rfc.substr(-3);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_get_person'));
    }
  }

  loadCurrentStep = async () => {
    try {
      const steps = await this.searchOnBoardingStepsQuery.execute({ sort_field: 'created_at' });
      this.current_step = steps.find((step) => step.current_step === 'confirm_identity_data')!;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_get_step_information'));
    }
  }

  loadAgreementTypes = async () => {
    try {
      const agreements = await this.searchAgreementsAsyncQuery.execute();
      const target_agreement = agreements.find((item) => item.name === 'confirmation_of_personal_data');
      this.customer_agreements[0].agreement_type_id = target_agreement!.id;
      this.customer_agreements[0].description = target_agreement!.description;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_get_agreements'));
    }
  }

  initialize = async () => {
    this.loading = true;
    await this.changeTitle();
    await this.loadPersonalInformation();
    await this.loadAgreementTypes();
    await this.loadCurrentStep();
    this.loading = false;
  }
}
