import i18n from '@/vue-app/plugins/i18n';

// Domain
import { PersonEntity } from '../../domain/entities/person-entity';

export default class PersonPresenter {
  readonly i18n_common_namespace = 'components.common.gender';

  private person_entity: PersonEntity

  private formatted_date_of_birth?: string;

  public constructor(person_entity: PersonEntity) {
    this.person_entity = person_entity;
  }

  get humanized_date_of_birth() {
    if (!this.formatted_date_of_birth) {
      this.formatted_date_of_birth = this.person_entity.date_of_birth.split('-').reverse().join('-');
    }

    return this.formatted_date_of_birth;
  }

  get translated_gender() {
    return i18n.tc(`${this.i18n_common_namespace}.${this.person_entity.gender.name}`);
  }
}
