
























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { VForm } from '@/vue-app/types/vuetify';

import ConfirmPersonalInformationViewModel
  from '@/vue-app/view-models/components/on-boarding/verify-identity/confirm-personal-information-view-model';

@Component({
  name: 'OnBoardingVerifyIdentityConfirm',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class OnBoardingVerifyIdentityConfirm extends Vue {
  confirm_view_model = Vue.observable(new ConfirmPersonalInformationViewModel(this));

  $refs!: {
    form: VForm;
  }

  @Watch('confirm_view_model.confirm_data')
  handleConfirmData(confirm_data: boolean) {
    this.confirm_view_model.handleConfirmData(confirm_data);
  }

  async created() {
    await this.confirm_view_model.initialize();
    this.$refs.form.validate();
  }
}

